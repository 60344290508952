
<template>
  <div>
     <JobSummary v-if="!hide"
      :location="location"
      :jobSummaryData ="jobData"
      >
      
       </JobSummary>
    <CRow class="mt-2">
        <CCol lg="12">
          <CCard>
        <CDataTable
          :table-filter="true"
          hover
          :items="items"
          :fields="fields"
          
          sorter
          pagination
          dense
          responsive
        >

        <template #start="{ item }">
        <td>
         
            {{ showDate(item) }}
          
        </td>
      </template>
           <template #status="{ item }">
        <td>
          <CBadge :color="showStatus(item)">
            {{ item.status }}
          </CBadge>
        </td>
      </template>
      
          <template #show_details="{item, index}">
            <td class="py-2">
              <CButton
                color="danger"
                variant="outline"
                square
                size="sm"
                @click="getJobById(item, index)"
              >
                {{ Boolean(item._toggled) ? "less" : "more" }}
              </CButton>
            </td>
          </template>
        </CDataTable>
        </CCard>
        </CCol>
      </CRow>
</div>
</template>


<style scoped>
</style>

<script>
import JobSummary from "@/components/reports/JobSummary";

export default {
  name: "JobView",
  components: {
    JobSummary
  },
  data(){
      return {
          items: [], 
          hide: true, 
          jobData: null, 
          fields:[{key: "id", label:"ID"}, 
          {key: "name", label:"Truck"},
          {key: "start", label: "Date"}, 
          {key: "device", label:"Device"},
          {key: "status", label:"Status"}, 
          {key:"show_details",label:"Details",responsivePriority:1}],
      }
  },
  computed:{
      
      location(){
        return this.getLocation(); 
      }
  },
  methods:
  {
    
    async getJobById(item,index){
      
      let temp = await this.$api.getJobById(item.id);
      
      this.jobData = temp.data; 
      this.hide =  (this.jobData==null);
      console.log(this.jobData);

      
      // TODO: Update the Job Info accordingly 

    }, 

    getLocation(){
      if(this.jobData)
        return this.jobData.location; 
      else
        return [0.0, 0.0];
    },

    showDate(item){
        return this.$moment(item.start).format("MMM DD YYYY hh:mm:ss A")
    },
    /** Returns the badge color  */
    showStatus(item){
      let ret = "warning"
        switch(item.status){
          case("completed"):
          ret = "success"; 
          break;
          default:
          case("pending"):
          ret = "warning"; 
          break;
        }
        return ret; 
    }
  },
  mounted(){
      try{

        this.$api.getJobs().then((res)=>{
          if(res.status == 200){
          this.items = res.data; 
          
          }
        }); 
        
      }
      catch(err){
          console.log(err); 
      }
      finally{
         

      }


  }, 
};
</script>