var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.hide
        ? _c("JobSummary", {
            attrs: { location: _vm.location, jobSummaryData: _vm.jobData }
          })
        : _vm._e(),
      _c(
        "CRow",
        { staticClass: "mt-2" },
        [
          _c(
            "CCol",
            { attrs: { lg: "12" } },
            [
              _c(
                "CCard",
                [
                  _c("CDataTable", {
                    attrs: {
                      "table-filter": true,
                      hover: "",
                      items: _vm.items,
                      fields: _vm.fields,
                      sorter: "",
                      pagination: "",
                      dense: "",
                      responsive: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "start",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c("td", [
                              _vm._v(" " + _vm._s(_vm.showDate(item)) + " ")
                            ])
                          ]
                        }
                      },
                      {
                        key: "status",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "td",
                              [
                                _c(
                                  "CBadge",
                                  { attrs: { color: _vm.showStatus(item) } },
                                  [_vm._v(" " + _vm._s(item.status) + " ")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      },
                      {
                        key: "show_details",
                        fn: function(ref) {
                          var item = ref.item
                          var index = ref.index
                          return [
                            _c(
                              "td",
                              { staticClass: "py-2" },
                              [
                                _c(
                                  "CButton",
                                  {
                                    attrs: {
                                      color: "danger",
                                      variant: "outline",
                                      square: "",
                                      size: "sm"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.getJobById(item, index)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          Boolean(item._toggled)
                                            ? "less"
                                            : "more"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }